import { render, staticRenderFns } from "./DobOrAgeFormGroup.vue?vue&type=template&id=2544f7d2&lang=pug&"
import script from "./DobOrAgeFormGroup.vue?vue&type=script&lang=js&"
export * from "./DobOrAgeFormGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateTimeInput: require('/codebuild/output/src1221889695/src/frontend/components/DateTimeInput.vue').default})
