import { render, staticRenderFns } from "./FindTaxQuartersGuidance.vue?vue&type=template&id=c410adce&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Anchor: require('/codebuild/output/src1221889695/src/frontend/components/Anchor.vue').default})
