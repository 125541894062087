import { render, staticRenderFns } from "./ConsultationNotes.vue?vue&type=template&id=52c44446&lang=pug&"
import script from "./ConsultationNotes.vue?vue&type=script&lang=js&"
export * from "./ConsultationNotes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PossibleCsnpConditions: require('/codebuild/output/src1221889695/src/frontend/components/PossibleCsnpConditions.vue').default})
