import { render, staticRenderFns } from "./Drugs.vue?vue&type=template&id=b9c334ce&lang=pug&"
import script from "./Drugs.vue?vue&type=script&lang=js&"
export * from "./Drugs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src1221889695/src/frontend/components/Icon.vue').default,DrugAddItem: require('/codebuild/output/src1221889695/src/frontend/components/DrugAddItem.vue').default,Spinner: require('/codebuild/output/src1221889695/src/frontend/components/Spinner.vue').default,DrugForm: require('/codebuild/output/src1221889695/src/frontend/components/DrugForm.vue').default})
