import { render, staticRenderFns } from "./MatchingClientsAlert.vue?vue&type=template&id=6b1dacb2&lang=pug&"
import script from "./MatchingClientsAlert.vue?vue&type=script&lang=js&"
export * from "./MatchingClientsAlert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/codebuild/output/src1221889695/src/frontend/components/Spinner.vue').default,Name: require('/codebuild/output/src1221889695/src/frontend/components/Name.vue').default})
