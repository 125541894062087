import { render, staticRenderFns } from "./PlanCompare.vue?vue&type=template&id=5e3fda1f&scoped=true&lang=pug&"
import script from "./PlanCompare.vue?vue&type=script&lang=js&"
export * from "./PlanCompare.vue?vue&type=script&lang=js&"
import style0 from "./PlanCompare.vue?vue&type=style&index=0&id=5e3fda1f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e3fda1f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src1221889695/src/frontend/components/Icon.vue').default,PlansTable: require('/codebuild/output/src1221889695/src/frontend/components/PlansTable.vue').default})
