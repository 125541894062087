import { render, staticRenderFns } from "./ActionsEnrollPlan.vue?vue&type=template&id=a3e83a76&lang=pug&"
import script from "./ActionsEnrollPlan.vue?vue&type=script&lang=js&"
export * from "./ActionsEnrollPlan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EnrollForm: require('/codebuild/output/src1221889695/src/frontend/components/EnrollForm.vue').default})
